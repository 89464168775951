import Cookies from 'js-cookie'

const $popups = document.querySelectorAll('.act-popup');
const $close_popups = document.querySelectorAll('.act-popup__btn-close');
if ($popups) {
    act_popup_show_auto_modal();
}


function act_popup_show_auto_modal() {

    $close_popups.forEach(function ($close_popup) {
        $close_popup.addEventListener('click', (ele) => {
            var $current_popup = ele.target.closest('.act-popup');
            close_modal($current_popup);
        });
    });
    $popups.forEach(function ($popup) {
        var id = $popup.getAttribute('id');
        // check if cookie exists, which would mean don't open the modal
        if (Cookies.get(id) == 'closed') {
            $popup.classList.remove('act-popup--visible');
            return;
        }
        $popup.classList.add('act-popup--visible');
    });
}

function close_modal(popup_el) {
    popup_el.classList.remove('act-popup--visible');
    var id = popup_el.getAttribute('id');
    var expire = popup_el.getAttribute('data-expire');
    Cookies.set(id, 'closed', { expires: parseInt(expire) });
}
